import React from "react";
import styled from "styled-components";
import { AiOutlineInstagram } from "react-icons/ai";
import { Slide } from "react-awesome-reveal";
import { FaFacebook } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";


const ProfComponent = () => {
  return (
    <Container id="home">
      <Slide direction="left">
        <Texts>
          <h4>
            {/* Hello <span className="green">I'am</span> */}
            Travel with Pakistan's Most Experienced Guides
          </h4>
          <h1 className="green">
            {`Explore Pakistan's Hidden Gems`}
          </h1>
          {/* <h3>CEO & Co-Founder</h3> */}
          {/* <p>
            Fatima Bashir Malik is the CEO and Founder, with a rich background as a social researcher and analyst. She brings her passion for travel to life by organizing and guiding domestic tours, creating unique and memorable experiences for every traveler.
          </p> */}
          <p>
            Embark on a journey to Pakistan's most breathtaking destinations.Travel with us and make memories that last a lifetime.Discover the untouched beauty and vibrant culture of Pakistan.Let us guide you to the heart of Pakistan's wonders.Adventure, relaxation, and cultural immersion — all in one trip.
          </p>
          <a href="https://wa.me/message/DMPI7TCXO25NK1">
            <button onClick={() => {}}>Book Now</button>
          </a>
          {/* <Social>
            <p>Check out my</p>
            <div className="social-icons">
              <span>
                <a href="https://www.instagram.com/travelwithfatima2021?igsh=MW44ajZ2YW4zb2Vreg==">
                  <AiOutlineInstagram />
                </a>
              </span>
              <span>
                <a href="https://www.facebook.com/countrysidetours2021">
                  <FaFacebook />
                </a>
              </span>
              <span>
                <a href="https://www.tiktok.com/@travelwithfatima2021?_t=8pL6LvXkUqu&_r=1">
                  <FaTiktok />
                </a>
              </span>
              <span>
                <a href="https://wa.me/message/DMPI7TCXO25NK1">
                  <BsWhatsapp />
                </a>
              </span>
            </div>
          </Social> */}
        </Texts>
      </Slide>
      <Slide direction="right">
        <Profile>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/travel-with-fatima.appspot.com/o/hero-section-image%2FImage(1).png?alt=media&token=55258561-af6a-48f3-b7af-c5094c652693"
            alt="profile"
          />
        </Profile>
      </Slide>
    </Container>
  );
};

export default ProfComponent;

const Container = styled.div`
  display: flex;
  gap: 2rem;
  padding-top: 3rem;
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  z-index: 1;
  @media (max-width: 840px) {
    width: 90%;
  }

  @media (max-width: 640px) {
    flex-direction: column;
  }
`;
const Texts = styled.div`
  flex: 1;
  h4 {
    padding: 1rem 0;
    font-weight: 500;
  }
  h1 {
    font-size: 3rem;
    font-family: "Secular One", sans-serif;
    letter-spacing: 2px;
  }
  h3 {
    font-weight: 500;
    font-size: 1.2rem;
    padding-bottom: 1.2rem;
    text-transform: capitalize;
  }
  p {
    font-weight: 300;
  }

  button {
    padding: 0.7rem 2rem;
    margin-top: 3rem;
    cursor: pointer;
    background-color: #F6B033;
    border: none;
    color: #fff;
    font-weight: 500;
    // filter: drop-shadow(0px 10px 10px #F6B033);
    :hover {
      filter: drop-shadow(0px 10px 10px #F6B033);
    }
  }
`;
const Social = styled.div`
  margin-top: 3rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  p {
    font-size: 0.9rem;
    @media (max-width: 690px) {
      font-size: 0.7rem;
    }
  }

  .social-icons {
    display: flex;
    align-items: center;
    gap: 1rem;
    span {
      width: 2.3rem;
      height: 2rem;
      clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
      background-color: #F6B033;
      position: relative;
      transition: transform 400ms ease-in-out;
      :hover {
        transform: rotate(360deg);
      }
    }

    a {
      color: #fff;
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;
const Profile = styled.div`
  img {
    width: 25rem;
    border-radius:1rem;
    transition: transform 400ms ease-in-out;
    @media (max-width: 790px) {
      width: 20rem;
    }

    @media (max-width: 660px) {
      // width: 18rem;
      display:none
    }

    @media (max-width: 640px) {
      width: 100%;
    }
  }

  :hover img {
    transform: translateY(-10px);
  }
`;
