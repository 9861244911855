import React, { useRef } from "react";
import Slider from "react-slick";
import Project from "./Project";
import FirstImage from "../../assets/7.jpg";
import secondImage from "../../assets/8.jpg";
import thirdImage from "../../assets/9.jpg";
import fourthImage from '../../assets/10.jpg'
import fifthImage from '../../assets/11.jpg'
import sixthImage from '../../assets/14.jpg'
import seventhImage from '../../assets/15.jpg'
import eighthImage from '../../assets/16.jpg'
import ninthImage from '../../assets/17.jpg'
import tenthImage from '../../assets/18.jpg'
import eleventhImage from '../../assets/19.jpg'
import twelethImage from '../../assets/1.jpg'
import thirteenImage from '../../assets/3.jpg'
import fourteenImage from '../../assets/5.jpg'
import fifteenImage from '../../assets/6.jpg'
import sixteenImage from '../../assets/2.jpg'
import seventeenImage from '../../assets/4.jpg'
import eighteenImage from '../../assets/12.jpg'
import nineteenImage from '../../assets/20.jpg'
import twentyImage from '../../assets/21.jpg'
import twentyoneImage from '../../assets/13.jpg'
















import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import styled from "styled-components";

let data = [
  {
    img: FirstImage,
    disc: "1 day package 4500/= per person. Shogran and siripaye",
  },
  {
    img: secondImage,
    disc: "1 day package 3500/= per person. Umbrella Waterfall",
  },
  {
    img: thirdImage,
    disc: "1 day package 3700/= per person. Pir chinasi Kashmir",
  },
  {
    img: fourthImage,
    disc: "1 day package 3500/= per person. Mushkpuri Top",
  },
  {
    img: fifthImage,
    disc: "1 day package 4500/= per person. Sharan Forest",
  },
  {
    img: ninthImage,
    disc: "1 day package 3500/= per person & 7000/= Couple. Ayubia pipeline track Muree",
  },
  {
    img: tenthImage,
    disc: "1 day package 3500/= per person & 7000/= Couple. Ganga choti Kashmir",
  },
  {
    img: eleventhImage,
    disc: "1 day package 3500/= per person & 7000/= Couple. Malam jabba sawat",
  },
  {
    img: sixthImage,
    disc: "2 days package 11000/= per person & 25000/= Couple. Shogran Siripaye and khanpur Dam",
  },
  {
    img: seventhImage,
    disc: "2 days package 11000/= per person & 25000/= Couple. Sharan Forest and khanpur Dam",
  },
  {
    img: eighthImage,
    disc: "2 days package 11000/= per person & 25000/= Couple. Manoor Valley and khanpur Dam",
  },
  {
    img: twelethImage,
    disc: "3 days package 14500/= per person & 35000/= Couple. Kumrat valley",
  },
  {
    img: twelethImage,
    disc: "3 days package 14500/= per person & 35000/= Couple. Kumrat valley",
  },
  {
    img: thirteenImage,
    disc: "3 days package 14500/= per person & 35000/= Couple. Neelum valley",
  },
  {
    img: fourteenImage,
    disc: "3 days package 14500/= per person & 35000/= Couple. Kalam, Sawat",
  },
  {
    img: fifteenImage,
    disc: "3 days package 14500/= per person & 35000/= Couple. Naran Valley",
  },
  {
    img:sixteenImage,
    disc: "4 days package 19000/= per person & 45000/= Couple. Kumrat Valley",
  },
  {
    img: seventeenImage,
    disc: "4 days package 19000/= per person & 45000/= Couple. Neelum Valley",
  },
  {
    img: eighteenImage,
    disc: "5 days package 25500/= per person & 55000/= Couple. Hunza Valley",
  },
  {
    img: nineteenImage,
    disc: "5 days package 25000/= per person & 55000/= Couple. Fairy Meadows",
  },
  {
    img: twentyImage,
    disc: "6 days package 45000/= per person & 110000/= Couple. Astore Minimerg",
  },
  {
    img: twentyoneImage,
    disc: "8 days package 34000/= per person & 750000/= Couple. Hunza & Skardu",
  },
];

var settings = {
  className: "center",
  centerMode: true,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  arrows: false,
  responsive: [
    {
      breakpoint: 990,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        centerMode: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        centerMode: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
      },
    },
  ],
};
const SliderComp = () => {
  const arrowRef = useRef(null);
  let sliderProject = "";
  sliderProject = data.map((item, i) => <Project item={item} key={i} />);
  return (
    <Container>
      <Slider ref={arrowRef} {...settings}>
        {sliderProject}
      </Slider>
      <Buttons>
        <button onClick={() => arrowRef.current.slickPrev()} className="back">
          <IoIosArrowBack />
        </button>
        <button onClick={() => arrowRef.current.slickNext()} className="next">
          <IoIosArrowForward />
        </button>
      </Buttons>
    </Container>
  );
};

export default SliderComp;

const Container = styled.div`
  position: relative;
`;

const Buttons = styled.div`
  button{
    width: 2rem;
    height: 2rem;
    background-color: rgba(255, 255, 255, 0.100);
    cursor: pointer;
    color: #4F2C53;
    border: none;
    position: absolute;
    top: 45%;
    right: -1rem;
  }

  .back{
    left: -1rem;
    background-color:white;
    border:1px solid gray
  }
  .next{
     background-color:white;
    border:1px solid gray
  }
`;
