import React from "react";
import { MdFastfood } from "react-icons/md";
import { BiSolidBus } from "react-icons/bi";
import { BiHotel } from "react-icons/bi";
import styled from "styled-components";
import Card from "./card"
import { Slide } from "react-awesome-reveal";

const MyTeam = () => {
    return (
        <Container id="teams">
            <Slide direction="down">
                <h4>
                    Our <span className="green">team</span>
                </h4>
                <h1>Professional Tour Guides</h1>
            </Slide>
            <Cards>
                <Slide direction="right">
                    <Card
                        imageUrl={"https://firebasestorage.googleapis.com/v0/b/travel-with-fatima.appspot.com/o/hero-section-image%2FWhatsApp%20Image%202024-08-30%20at%2015.55.15.jpeg?alt=media&token=b2ca57b6-a6e9-4e9e-8750-54f5c59540ed"}
                        name={"Fatima Awan"}
                        designation={`CEO & CO-Founder`}
                    />
                </Slide>
                <Slide direction="left">
                    <Card
                        imageUrl={"https://firebasestorage.googleapis.com/v0/b/travel-with-fatima.appspot.com/o/myTeam%2FWhatsApp%20Image%202024-08-30%20at%2022.36.09.jpeg?alt=media&token=1afdcca3-270c-4b45-a23b-2c6dbad67748"}
                        name={"Muhammad Awais Awan "}
                        designation={`Transportor manager`}
                    />
                </Slide>
                <Slide direction="up">
                    <Card
                        imageUrl={"https://firebasestorage.googleapis.com/v0/b/travel-with-fatima.appspot.com/o/myTeam%2FWhatsApp%20Image%202024-08-30%20at%2021.48.21.jpeg?alt=media&token=72c6da3a-153c-41af-bce3-cc496c7bce6f"}
                        name={"Kiran ikram"}
                        designation={`Tour guide`}
                    />
                </Slide>
            </Cards>
        </Container>
    );
};

export default MyTeam;

const Container = styled.div`
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 3rem 0;
  @media (max-width: 840px) {
    width: 90%;
  }

  h1 {
    padding-top: 1rem;
  }
`;
const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-top: 4rem;
  gap: 1rem;
`;
