import React from "react";
import { MdFastfood } from "react-icons/md";
import { BiSolidBus } from "react-icons/bi";
import { BiHotel } from "react-icons/bi";
import styled from "styled-components";
import Card from "./Card";
import { Slide } from "react-awesome-reveal";

const Services = () => {
  return (
    <Container id="service">
      <Slide direction="down">
        <h4>
          Our <span className="green">services</span>
        </h4>
        <h1>Included in Trip</h1>
      </Slide>
      <Cards>
        <Slide direction="left">
          <Card
            Icon={BiSolidBus}
            title={"Transport"}
            disc={`GLI, BRV , Grandcabin, Coaster 4C/5C`}
          />
        </Slide>
        <Slide direction="up">
          <Card
            Icon={MdFastfood}
            title={"Meals"}
            disc={`Breakfast & Dinner`}
          />
        </Slide>
        <Slide direction="right">
          <Card
            Icon={BiHotel}
            title={"Hotel"}
            disc={`Twin sharing for couple , Quad sharing for groups`}
          />
        </Slide>
      </Cards>
    </Container>
  );
};

export default Services;

const Container = styled.div`
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 3rem 0;
  @media (max-width: 840px) {
    width: 90%;
  }

  h1 {
    padding-top: 1rem;
  }
`;
const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-top: 4rem;
  gap: 1rem;
`;
