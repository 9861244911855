import React, { useRef } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import ClientSlider from "./ClientSlider";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Slide } from "react-awesome-reveal";

let clients = [
  {
    name: "Ali Ahmed Qureshi",
    position: "web developer",
    img_url:
      "https://firebasestorage.googleapis.com/v0/b/travel-with-fatima.appspot.com/o/clients%2FScreenshot%202024-08-31%20at%207.56.28%E2%80%AFAM.png?alt=media&token=8add5450-4049-43f1-a653-255bd7ca14b8",
    stars: 5,
    disc: `Please accept our heartfelt gratitude for being such great host of wonderful tour.Your team took us to places we hardly have any idea about.You and this trip will be etched out in our memory forever`,
  },
  {
    name: "Shahraiz Naqvi",
    position: "web developer",
    img_url:
      "https://firebasestorage.googleapis.com/v0/b/travel-with-fatima.appspot.com/o/clients%2FScreenshot%202024-08-31%20at%208.07.49%E2%80%AFAM.png?alt=media&token=0703603d-09ff-4b50-8433-c925dddd84af",
    stars: 5,
    disc: `This was my first trip to Shogran on 26-12-2021 with travel with fatima and i absolutely love it.The tour guide Fatima was very cooperative.Thanks to her, the trip went smoothly with any worry.`,
  },
  {
    name: "Haider Gondal",
    position: "web developer",
    img_url:
      "https://firebasestorage.googleapis.com/v0/b/travel-with-fatima.appspot.com/o/clients%2FScreenshot%202024-08-31%20at%208.14.49%E2%80%AFAM.png?alt=media&token=59201597-e6e5-459f-84fe-c2fd7fa2fdb4",
    stars: 5,
    disc: `It was my second time with you guys, and honestly, I really enjoyed it a lot. Thanks for the hospitality! The food was good, and the guide was friendly. I truly appreciate your efforts in making this trip a beautiful memory.`,
  },
  // {
  //   name: "John Michel",
  //   position: "web developer",
  //   img_url:
  //     "https://t4.ftcdn.net/jpg/02/90/27/39/360_F_290273933_ukYZjDv8nqgpOBcBUo5CQyFcxAzYlZRW.jpg",
  //   stars: 5,
  //   disc: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. 
  //       Temporibus consequuntur dolores labore natus similique nemo doloribus cum accusantium adipisci maiores.`,
  // },
];
var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  arrows: false,
  responsive: [
    {
      breakpoint: 990,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 530,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Clients = () => {
  const arrowRef = useRef(null);
  let clientDisc = "";
  clientDisc = clients.map((item, i) => <ClientSlider item={item} key={i} />);
  return (
    <Container id="client">
      <Slide direction="left">
        <span className="green">testimonials</span>
        <h1>what clients say</h1>
      </Slide>
      <Testimonials>
        <Slider ref={arrowRef} {...settings}>
          {clientDisc}
        </Slider>
        <Buttons>
          <button onClick={() => arrowRef.current.slickPrev()}>
            <IoIosArrowBack />
          </button>
          <button onClick={() => arrowRef.current.slickNext()}>
            <IoIosArrowForward />
          </button>
        </Buttons>
      </Testimonials>
    </Container>
  );
};

export default Clients;

const Container = styled.div`
    width: 80%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 4rem 0;

    @media(max-width:840px){
        width: 90%;
    }

    span{
        font-weight: 700;
        text-transform: uppercase;
    }

    h1{
        padding-top: 1rem;
        text-transform: capitalize;
    }

    .slick-list, .slick-slider, .slick-track{
        padding: 0;
    }

    .slick-dots{
        text-align: left;
        margin-left: 1rem;
    }

    .slick-dots li button:before{
        content: "";
    }

    .slick-dots li button{
        width: 9px;
        height: 4px;
        background: linear-gradient(159deg, rgb(45, 45, 58) 0%, rgb(43, 43, 53) 100%);
        padding: 0.1rem;
        margin-top: 1rem;
        transition: all 400ms ease-in-out;
        border-radius: 50px;
    }
    
    .slick-dots li.slick-active button{
        background: #4F2C53;
        width: 15px;
    }

    .slick-dots li{
        margin: 0;
    }
`;

const Testimonials = styled.div`
    margin-top: 2rem;
    position: relative;
`;
const Buttons = styled.div`
    position: absolute;
    right: 0.7rem;
    bottom: -2rem;

    button{
        background-color: transparent;
        margin-left: 0.5rem;
        border: none;
        color: #4F2C53;
        cursor: pointer;
        font-size: 1.1rem;
    }

    @media(max-width:530px){
        display: none;
    }
`;
