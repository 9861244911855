import React from "react";
import styled from "styled-components";

const Project = (props) => {
  const { img, disc } = props.item;
  return (
    <Container className="project">
      <img src={img} alt="project" />
      <div className="disc">
        {/* <h1>Description</h1> */}
        <p>
          {disc}
          <a href="https://wa.me/message/DMPI7TCXO25NK1">
            <button>Enquire</button>
          </a>
          <a href={img} target="_blank" rel="noopener noreferrer">
            <button>View Full Image</button>
          </a>
        </p>
      </div>
    </Container>
  );
};

export default Project;

const Container = styled.div`
    height: 22rem;
    background-color: transparent;
    margin: 0 0.5rem;
    // padding: 0.5rem;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        // transition: transform 400ms ease-in-out;
    }
    .disc{
        position: absolute;
        right: 0;
        color:white;
        left: 0;
        bottom: -10rem;
        text-align: left;
        padding: 0.5rem;
        background: linear-gradient(rgba(0,0,0, 0.100), rgba(0,0,0, 0.80));
        transition: all 400ms ease-in-out;
        h1{
            font-size: 1rem;
        }
    
        p{
            width: 100%;
            font-size: 0.6rem;
            a{
                margin-left: 0.1rem;
                color: red;
            }
                button {
        margin-top: 0.5rem;
        margin-left:0.5rem;
        padding: 0.3rem 0.6rem;
        background-color: #69AE37;
        color: white;
        border: none;
        border-radius: 3px;
        cursor: pointer;
        font-size: 0.7rem;
        transition: background-color 300ms ease-in-out;

        &:hover {
          background-color: #69AE37;
        }
      }
        }
    }

    :hover > img{
        // transform: scale(1.3);
    }

    :hover > .disc{
        bottom: 0;
    }

`;
