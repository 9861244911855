import React from "react";
import styled from "styled-components";

const Card = (props) => {
    const { imageUrl, name, designation } = props;
    return (
        <Container>
            <ImageContainer>
                <img src={imageUrl} alt={name} />
            </ImageContainer>
            <Details>
                <Name>{name}</Name>
                <Designation>{designation}</Designation>
            </Details>
        </Container>
    );
};

export default Card;

const Container = styled.div`
  width: 100%;
  max-width: 300px; /* Optional: Set a max width for the card */
  background: white;
  padding: 1rem;
  text-align: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px; /* Rounded corners */
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 200px; /* Adjust the height as needed */
  overflow: hidden; /* Ensures the image doesn't overflow the container */
  border-radius: 8px 8px 0 0; /* Top rounded corners for the image container */

  img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the container proportionately */
  }
`;

const Details = styled.div`
  margin-top: 1rem;
`;

const Name = styled.h1`
  font-size: 1.2rem;
  margin: 0;
`;

const Designation = styled.p`
  font-size: 0.9rem;
  color: gray; /* Optional: Add a different color for the designation */
  margin: 0.5rem 0 0;
`;
